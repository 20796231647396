import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { motion } from "framer-motion";
import Sesion from "./components/Sesion";
import { sesionIniciada} from './controllers/configuracionFirebase';
import toast, { Toaster } from 'react-hot-toast';
import Loading from './components/Loading';
import WebFont from 'webfontloader';
import SobreNosotros from './components/SobreNosotros';
import { Avatar } from 'primereact/avatar';
import { chat } from './controllers/ai';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { OverlayPanel } from 'primereact/overlaypanel';

function App() {

  const [ usuario, setUsuario ] = useState();
  const [ contadorMessage, setContadorMessage ] = useState(0);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ seconds, setSeconds ] = useState(3);
  const [ messageEspera, setMessageEspera ] = useState(false);
  const [ message, setMessage ] = useState("");
  const [ messages, setMessages ] = useState([
    {
      role: "user",
      parts: [{ text: "" }],
    },
    {
      role: "model",
      parts: [{ text: "¡Hola! Soy Bolt, la mascota de PROWEBSPORTS 😊 Estoy aquí para resolver tus dudas y ayudarte a usar la plataforma 🖥️. ¿En qué puedo ayudarte hoy? 🤔✨" }],
    },
  ]);

  const asistenteRef = useRef(null);
  const containerRef = useRef(null);
  const mensajeRef = useRef(null);
  const escribiendoRef = useRef(null);

  const handleSend = () => {
    if (message.trim()) {
      setMessageEspera(true);
      if (escribiendoRef.current) {
        escribiendoRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      chatbot(message);
      setMessage("");
    }
  };
  
  async function chatbot(text){
    try {
      let auxText = '';
      if(contadorMessage === 0 && usuario){
        auxText = `Hola, soy ${usuario.nombreclub}. ${text}`;
        setContadorMessage(1);
      }
      const result  = await chat.sendMessage(auxText === '' ? text : auxText);
      const auxHistorial = [...messages];
      const chatActual = [{
        role: "user",
        parts: [{ text }],
      },
      {
        role: "model",
        parts: [{ text: result.response.text() }],
      }]
      setMessages([...auxHistorial, ...chatActual]);
      setMessageEspera(false);
    } catch (error) {
      console.error("Error al obtener la respuesta: ", error);
    }
  }

  useEffect(() => {
    sesionIniciada(setUsuario);

    WebFont.load({
      google: {
        families: [
          "Abril Fatface",
          "IBM Plex Mono",
          "Acme",
          "Yeseva One",
          "Fira Sans Extra Condensed",
          "Poppins",
          "Merriweather Sans",
          "Bebas Neue",
          "EB Garamond",
          "Alkatra",
          "Calistoga",
          "Oleo Script",
          "Libre Baskerville",
          "Lora",
          'Anton SC',
          'Gothic A1',
        ]
      }
    });

    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 920);
    if (seconds === 0) {
      setIsLoading(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [seconds])

  useEffect(() =>{
    if (mensajeRef.current && containerRef.current) {
      mensajeRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  },[messages])

  useEffect(() =>{
    const waitForRef = () => {
      if (asistenteRef.current) {
        const auxEvent = { currentTarget: document.getElementById('asistente') };
        asistenteRef.current.show(auxEvent);
      } else {
        setTimeout(waitForRef, 4000);
      }
    };

    waitForRef(); 
  },[])

  return (
    <div className="App">
      {isLoading ?(
        <Loading/>
      ):(
      <Router>
        <motion.nav
          className="navbar navbar-expand-lg bg-light"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          style={{zIndex: 1000}}
        >
          <div className="container-fluid">
            <div>
              <img
                className='img-barra-menu'
                style={{ maxWidth: "50px" }}
                src={require("./img/img-logo-swimmingt.webp")}
                alt="logo prowebsports"
              />
            </div>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="w-100 navbar-nav justify-content-end me-auto mb-2 mb-lg-0">
                {/*
                <li className="nav-item item-principal">
                  <Link
                    className="nav-link link-principal"
                    to="/"
                  >TORNEOS</Link>
                </li>
                <li className="nav-item item-principal">
                  <Link
                    className="nav-link link-principal"
                    to="/afiliacion"
                  >AFILIACIÓN AL CLUB</Link>
                </li>
                
                 <li className="nav-item item-principal">
                   <Link
                     className="nav-link link-principal"
                     to="/"
                   >UNIFORME</Link>
                 </li>
                 <li className="nav-item item-principal">
                 <Link
                 className="nav-link link-principal"
                 to="/"
                 >CONTÁCTENOS</Link>
                 </li>
                
                <li className="nav-item item-principal">
                  <Link
                    className="nav-link link-principal"
                    to="/horarios"
                  >HORARIOS</Link>
                </li>
                <li className="nav-item item-principal">
                  <Link
                    className="nav-link link-principal"
                    to="/sobrenosotros"
                  >SOBRE NOSOTROS</Link>
                </li>
                */}
              </ul>
            </div>
          </div>
        </motion.nav>
        <Routes>
          <Route
            path="/*"
            element={
              <Sesion usuario={usuario} setUsuario={setUsuario}/>
            }
          />
          {/*
          <Route
            path="/sobrenosotros"
            element={
              <SobreNosotros/>
            }
          />
          */}
        </Routes>
        <Chip className='position-absolute' label={<span><b>BOLT</b> Asistente</span>} id='asistente' image={require('./img/pwsmascota-izquierda.png')} style={{top: '15px', right: '10px', zIndex: '1000', cursor: 'pointer'}} onClick={(e) => asistenteRef.current.toggle(e)}/>
        <OverlayPanel className='w-100 chat' ref={asistenteRef}>
          <div ref={containerRef} style={{ maxHeight: '300px', overflowY: 'auto'}}>
            {messages.map((mess, indexMess)=>{
              return <motion.div key={indexMess} className='d-flex'
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{duration: 1, delay: `0.${indexMess}`}}
              >
                {indexMess % 2 !== 0 ? (
                  <Avatar image={require('./img/pwsmascota-izquierda.png')} shape="circle" size='small'/>
                ):(
                  <></>
                )}
                <p
                ref={indexMess === messages.length - 1 ? mensajeRef : null}
                className={`${mess?.parts[0].text !== '' ? 'p-1' : ''} m-0 ${indexMess % 2 === 0 ? 'ms-auto bg-primary' : 'me-auto bg-danger'} text-start text-light mb-1`} 
                style={{borderRadius: '10px', width: '85%'}}>{mess?.parts[0].text}</p>
              </motion.div>
            })}
            <span
            ref={escribiendoRef}
            className='d-flex justify-content-start mt-2' style={{width: '85%'}}>
              <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: messageEspera ? 1 : 0 }}
              >Escribiendo...</motion.p>
            </span>
          </div>
          <div className="input-group mt-3">
            <textarea
            type="text"
            className="form-control"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Escribe un mensaje"
            rows={1}
            style={{ resize: 'none', overflow: 'hidden' }}
            ></textarea>
            <Button icon='pi pi-send' className="btn btn-success" onClick={handleSend}></Button>
          </div>
        </OverlayPanel>
      </Router>
      )}
      <Toaster containerStyle={{textAlign: 'start'}}/>
    </div>
  );
}

export default App;
